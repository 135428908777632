import { UserType } from "../enums/user-type.enum";
import { EntityModel } from "./entity.model";

export class BotResponseModel {
    id = 0;
    timestamp?: Date;
    constructor(
        public data: IBotResponseData,
        public from: UserType,
        public type: string,
        public subtype?: string
    ) {}
}

// tslint:disable-next-line:no-empty-interface
export interface IBotResponseData {}

export interface IHistoryData extends IBotResponseData {
    agent: string;
    message: string;
}

export interface IHandoverData extends IBotResponseData {
    provider: string;
    payload: IHandoverPayload;
}

// tslint:disable-next-line:no-empty-interface
export interface IHandoverPayload {}

export interface IAvayaHandoverData extends IHandoverPayload {
    channel: string;
    name: string;
    email: string;
    key: string;
    url: string;
}

export interface IQuandagoHandoverData extends IHandoverPayload {
    campaignid: string;
    chatbox: string;
    routepointid: string;
    server: string;
    handover_base_url: string;
    email: string;
    name: string;
}

export interface IPureConnectHandoverData extends IHandoverPayload {
    chatbox: string;
    handover_base_url: string;
    name: string;
    params: IPureConnectParam[];
}

export interface IPureConnectParam {
    id: number;
    key: string;
    value: string;
}

export interface IEngageHandoverData extends IHandoverPayload {
    guid: string;
}

export interface IChannelMeHandoverData extends IHandoverPayload {
    account_code: string;
    group: string;
}

export interface IChannelMeHandoverData extends IHandoverPayload {
    account_code: string;
    group: string;
}

export interface IExpressionData extends IBotResponseData {
    reply: string;
    html: string;
    rich_text_available: boolean;
    has_choices?: boolean;
    message?: string;
    author?: IAuthorData;
    processed_by_node_id?: string;
}

export interface IKnowledgebaseData extends IBotResponseData {
    title: string;
    body: string;
}

export interface IUserMessageData extends IBotResponseData {
    message: string;
    payload: {};
}

export interface IChoiceData {
    button_text?: string;
    value?: string;
    entity: EntityModel;
    intent: string;
}

export interface IQuickReplyData extends IBotResponseData {
    choices: IChoiceData[];
    disable_manual_input: boolean;
    result_entity?: string;
    cancel_button?: boolean;
    processed_by_node_id?: string;
}

export interface ISuggestionData extends IBotResponseData {
    button_text: string;
    entity: EntityModel;
    intent: string;
}

export interface IAttachmentData extends IBotResponseData {
    reply: {
        url: string;
        name: string;
    };
    author?: IAuthorData;
}

export interface IAuthorData {
    name: string,
    url: string
}
