import { BotResponseModel, IEngageHandoverData, IHandoverData, IHistoryData } from "./models/bot-response.model";
import { IEngageApi } from "./models/engageApi.model";
import { IHandoverProps } from "./models/handoverProps.model";
import { SessionInfoModel } from "./models/session-info.model";
import { Avaya } from "./providers/avaya";
import { ChannelMe } from "./providers/channelme";
import { Engage } from "./providers/engage";
import { PureConnect } from "./providers/pureconnect";
import { QuandaGo } from "./providers/quandago";

let obiHandMeOver: Engage = null;

export function createHandover(
    response: BotResponseModel,
    onNewMessage: (msg: BotResponseModel) => void,
    onEnd: () => void,
    onPropRecieved: (props: IHandoverProps) => void,
    onTyping: (typing: boolean) => void,
    history: IHistoryData[],
    sessionInfo: SessionInfoModel
) {
    let handover;
    switch ((response.data as IHandoverData).provider) {
        case "engage":
            /**
             * Use global variable obiHandMeOver to track previously established connection and reuse it;
             * this prevents multiple messages sent to multiple connections to Engage and creating unhappy customers
             */
            if (!obiHandMeOver) {
                obiHandMeOver = new Engage(response, onNewMessage, onTyping, onPropRecieved, onEnd, history);
            } else {
                if (
                    <IEngageApi>obiHandMeOver.chat &&
                    (<IEngageApi>obiHandMeOver.chat).guid !==
                        (<IEngageHandoverData>(<IHandoverData>response.data).payload).guid
                ) {
                    obiHandMeOver.disableInstance();
                    obiHandMeOver = new Engage(response, onNewMessage, onTyping, onPropRecieved, onEnd, history);
                }
                obiHandMeOver.$newMessage = onNewMessage;
                obiHandMeOver.$typing = onTyping;
                obiHandMeOver.$end = onEnd;
                obiHandMeOver.history = history;
                obiHandMeOver._onChatInit();
            }
            handover = obiHandMeOver;
            break;
        case "quandago":
            handover = new QuandaGo(response, onNewMessage, onTyping, onPropRecieved, onEnd, history, sessionInfo);
            break;
        case "channelme":
            handover = new ChannelMe(response, onNewMessage, onTyping, onPropRecieved, onEnd, history);
            break;
        case "pureconnect":
            handover = new PureConnect(
                response,
                onNewMessage,
                onTyping,
                onPropRecieved,
                onEnd,
                history
            );
            break;
        case "avaya":
            handover = new Avaya(response, onNewMessage, onTyping, onPropRecieved, onEnd, history, sessionInfo);
            break;
        default:
            console.log("No handover provider found");
    }
    return handover;
}
