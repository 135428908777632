declare let OBI: {
    // Global variable
    chat: (config: {}) => IEngageApi;
};

import { UserType } from "../enums/user-type.enum";
import {
    BotResponseModel,
    IAttachmentData,
    IEngageHandoverData,
    IExpressionData,
    IHandoverData,
    IHistoryData
} from "../models/bot-response.model";
import { IEngageApi } from "../models/engageApi.model";
import { EngageResponseModel } from "../models/engageResponse.model";
import { IHandoverProps } from "../models/handoverProps.model";
import { unescapeStringContent } from "../util/util";

export class Engage {
    public chat: IEngageApi;
    public engageInitialized = false;
    public engageEnded = false;
    public enabled = true;
    public sendAttachement = this._sendAttachement;
    public sendMessage = this._sendUserMessage;
    public sendContext = this._sendContextMessage;
    public sendTyping = this._sendUserTyping;
    public stop = this._stop;
    public disableInstance = this._disableInstance;
    $newMessage: (msg: BotResponseModel) => void;
    $typing: (typing: boolean) => void;
    $propRecieved: (props: IHandoverProps) => void;
    $end: () => void;
    history: IHistoryData[];
    script: HTMLScriptElement;

    constructor(
        response: BotResponseModel,
        onNewMessage: any,
        onTyping: any,
        onPropRecieved: any,
        onEnd: any,
        history: IHistoryData[]
    ) {
        this._loadScript(response);
        this.$newMessage = onNewMessage;
        this.$typing = onTyping;
        this.$propRecieved = onPropRecieved;
        this.$end = onEnd;
        this.history = history;
    }

    private _stop() {
        // tslint:disable-next-line:no-empty
        this.$newMessage = () => {};
        this.$propRecieved({canSendAttachment: false})
        // tslint:disable-next-line:no-empty
        this.$typing = () => {};
    }

    private _sendUserTyping(state: boolean) {
        this.chat.setIsTyping(state)
    }

    private _sendAttachement(event: Event) {
        this.chat.sendAttachment(event);
    }

    private _sendUserMessage(msg: string, name: string) {
        this.chat.sendUserMessage({
            author: { url: null, name },
            content: unescapeStringContent(msg)
        });
    }

    private _sendContextMessage(msg: string, name: string) {
        this.chat.sendContextMessage({
            author: { url: null, name },
            content: msg,
            displayInWidget: false
        });
    }

    private _loadScript(response: BotResponseModel) {
        const data = response.data as IHandoverData;

        if (data.payload) {
            const payload = data.payload as IEngageHandoverData;
            this.script = document.createElement("script");
            this.script.onload = () => {
                this.chat = this._initEngage();
            };
            this.script.src = "https://cloudstatic.obi4wan.com/chat/obi-launcher.js";
            this.script.id = "obi-chat-launcher";
            this.script.setAttribute("data-guid", payload.guid);
            this.script.setAttribute("data-config", "true");
            document.head.appendChild(this.script);
        }
    }

    private _disableInstance() {
        this.enabled = false
    }

    private _initEngage() {
        return OBI.chat({
            enableLauncher: false,
            headless: true,
            onChatInit: () => this._onChatInit(),
            onNewMessage: (message: EngageResponseModel) => this._onNewMessage(message),
            onTyping: (isTyping: { typing: boolean }) => this._onTyping(isTyping)
        });
    }

    public _onChatInit() {
        if (this.chat) {
            if (this.enabled) {
                if (!this.engageInitialized) {
                    if (this.history.length) this._sendHistory(this.history);
                    this.engageInitialized = true;
                } else if (this.engageEnded) {
                    this._sendReInitialize();
                    this.engageEnded = false;
                }
            }
            if (this.chat.hasOwnProperty("canSendAttachment") && this.enabled) {
                this.chat.canSendAttachment().then((canSendAttachment: boolean) => this._onPropRecieved({canSendAttachment}));
            }
        }
    }

    private _sendReInitialize() {
        this._sendContextMessage("Chat reinitialized", "handover chatbot");
    }

    private _sendHistory(history: IHistoryData[]) {
        const chatLog = history.map((item: IHistoryData) => `**${item.agent.toUpperCase().trim()}**: ${item.message.trim()}`);
        chatLog.unshift("History\n");
        this._sendContextMessage(chatLog.join("\n"), "handover chatbot");
    }

    private _onTyping(isTyping: { typing: boolean }) {
        this.$typing(isTyping.typing);
    }

    private _onPropRecieved(props: IHandoverProps) {
        this.$propRecieved(props)
    }

    private _onNewMessage(message: EngageResponseModel) {
        if (message.type === "outgoing" && this.enabled) {
            if (message.content) {
                if (message.content === "end handover") {
                    if (this.script) {
                        this.script.parentElement.removeChild(this.script);
                        this.script = undefined;
                    }
                    this.engageEnded = true;
                    this._onPropRecieved({canSendAttachment: false})
                    this.$end();
                } else {
                    this.$newMessage(
                        new BotResponseModel(
                            {
                                reply: message.content,
                                author: message.author
                            } as IExpressionData,
                            UserType.Agent,
                            "expression"
                        )
                    );
                }
            } else if (message.hasOwnProperty("attachment")) {
                this.$newMessage(
                    new BotResponseModel(
                        {
                            reply: message.attachment,
                            author: message.author
                        } as IAttachmentData,
                        UserType.Agent,
                        "attachment"
                    )
                );
            }
        }
    }
}
