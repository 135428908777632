import { AvayaResponseBodyMethod, AvayaResponseType } from "../enums/avaya.enum";
import { UserType } from "../enums/user-type.enum";
import { AvayaMessageModel } from "../models/AvayaMessage.model";
import { AvayaResponseModel, INewAttachment, INewMessage, INewParticipant } from "../models/avayaResponse.model";
import { BotResponseModel, IAvayaHandoverData, IExpressionData, IHandoverData, IHistoryData } from "../models/bot-response.model";
import { IHandoverProps } from "../models/handoverProps.model";
import { SessionInfoModel } from "../models/session-info.model";

export class Avaya {
    public chat: any;
    public sendAttachement = this._sendAttachement;
    public sendMessage = this._sendUserMessage;
    public sendTyping = this._sendUserTyping;
    public stop = this._stop;
    $newMessage: (msg: BotResponseModel) => void;
    $typing: (typing: boolean) => void;
    $propRecieved: (props: IHandoverProps) => void;
    $end: () => void;

    history: IHistoryData[];
    sessionInfo: SessionInfoModel;
    payload: IAvayaHandoverData;
    socket: WebSocket;
    agentTypeOut: number;

    constructor(
        response: BotResponseModel,
        onNewMessage: any,
        onTyping: any,
        onPropRecieved: any,
        onEnd: any,
        history: IHistoryData[],
        sessionInfo: SessionInfoModel
    ) {
        this.payload = (response.data as IHandoverData).payload as IAvayaHandoverData;
        this.$newMessage = onNewMessage;
        this.$typing = onTyping;
        this.$propRecieved = onPropRecieved;
        this.$end = onEnd;
        this.history = history;
        this.sessionInfo = sessionInfo;

        this.openWebsocket();
    }

    private openWebsocket() {
        this.socket = new WebSocket(`wss://${this.payload.url}/chat`);
        this.socket.onopen = () => this._login();
        this.socket.onmessage = (event: MessageEvent) => this._onNewMessage(JSON.parse(event.data));
        this.socket.onclose = (event: CloseEvent) => {
            if (event.wasClean) {
              console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
              console.log("[close] Connection died", event);
            }
        };

        this.socket.onerror = (error: Event) => console.error("[error] WebSocket error observed:", error);
    }

    private _login() {
        const message = new AvayaMessageModel("1.0", "request", {
            method: "requestChat",
            deviceType: navigator.userAgent,
            routePointIdentifier: "Default",
            workFlowType: "",
            requestTranscript: false,
            calledParty: window.location.href,
            leaseTime: 1,
            intrinsics: {
                channelAttribute: "Chat",
                textDirection: document.dir.toUpperCase(),
                attributes: [this.payload.key + "." + this.payload.channel], // ["Location.Inhouse"],
                email: this.payload.email,
                name: this.payload.name,
                lastName: "",
                country: null, // optional country code ex: +31
                area: null, // optional area code ex: 020
                phoneNumber: "",
                topic: "",
                customFields: [{
                    title: "customFields title",
                    value: "customFields value"
                }]
            },
            priority: 5,
            customData: "test customData"
        });
        this.sendToAvaya(message);
    }

    private _stop() {
        const message = new AvayaMessageModel("1.0", "request", {
            method: "closeConversation"
        });
        this.sendToAvaya(message);
    }

    private _sendUserTyping(state: boolean) {
        const message = new AvayaMessageModel("1.0", "request", {
            method: "isTyping",
            isTyping: state
        });
        this.sendToAvaya(message);
    }

    private _sendAttachement(event: Event) {
        // TODO
    }

    private _sendUserMessage(msg: string) {
        const message = new AvayaMessageModel("1.0", "request", {
            method: "newMessage",
            message: msg,
            type: "text",
            data: {
                message: msg
            },
            customData: {}
        });
        this.sendToAvaya(message);
    }

    private sendToAvaya(message: AvayaMessageModel) {
        if (this.socket !== null) {
            this.socket.send(JSON.stringify(message));
        }
    }

    private _onNewMessage(message: AvayaResponseModel) {
        const body = message.body;
        if (message.type === AvayaResponseType.Notification) {
            switch (body.method) {
                case AvayaResponseBodyMethod.RequestChat:
                    this.$newMessage(
                        new BotResponseModel(
                            { reply: `Je staat in de wacht voor onze livechat. Een van onze medewerkers komt zo bij je.` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );

                    this._sendHistory();
                    break;
                case AvayaResponseBodyMethod.Pong:
                    break;
                case AvayaResponseBodyMethod.IsTyping:
                    this.handleIsTyping();
                    break;
                case AvayaResponseBodyMethod.NewParticipant:
                    this.$newMessage(
                        new BotResponseModel(
                            { reply: `Je bent nu verbonden met ${(body as INewParticipant).displayName}` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    break;
                case AvayaResponseBodyMethod.ParticipantLeave:
                    this.$newMessage(
                        new BotResponseModel(
                            { reply: `Verbinding met een medewerker is verbroken` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    this.$end();
                    break;
                case AvayaResponseBodyMethod.NewMessage:
                    this.$newMessage(
                        new BotResponseModel(
                            { reply: (body as INewMessage).message } as IExpressionData,
                            UserType.Agent,
                            "expression"
                        )
                    );
                    break;
                case AvayaResponseBodyMethod.NewAgentFileTransfer:
                    const attachementBody = (body as INewAttachment);
                    this.$newMessage(
                        new BotResponseModel(
                            {
                                reply: {
                                    type: attachementBody.mimeType,
                                    name: attachementBody.name,
                                    url: `https://${this.payload.url}/rest/attachment/${attachementBody.uuid}?workRequestId=${attachementBody.workRequestId}`,
                                }
                            },
                            UserType.Agent,
                            "attachment"
                        )
                    );

                    break;
                default:
                    console.log("Unknown message method", body)
                    break;
            }
        };
    }

    private handleIsTyping() {
        this.$typing(true);
        window.clearTimeout(this.agentTypeOut);
        this.agentTypeOut = window.setTimeout(() => this.$typing(false), 10000);
    }

    private _sendHistory() {
        const chatLog = this.history.map((item: IHistoryData) => `${item.agent.trim()}: ${item.message.trim()}`);
        chatLog.unshift("History");
        this._sendUserMessage(chatLog.join("\n"));
    }
}
