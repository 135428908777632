export default class SessionStorage {

    static prefix = "obi-";

    static get(key: string) {
        const item = window.sessionStorage.getItem(this.prefix + key);
        if (item) {
            return item;
        } else {
            return null;
        }
    }

    static add(key: string, value: string) {
        window.sessionStorage.setItem(this.prefix + key, value);
    }

    static remove(key: string) {
        window.sessionStorage.removeItem(this.prefix + key);
    }

    static clear() {
        for (const key in window.sessionStorage) {
            if (sessionStorage.hasOwnProperty(key) && key.indexOf(this.prefix) > -1) window.sessionStorage.removeItem(key);
        }
    }
}
