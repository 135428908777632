import { UserType } from "../enums/user-type.enum";
import {
    BotResponseModel,
    IChannelMeHandoverData,
    IExpressionData,
    IHandoverData,
    IHistoryData
} from "../models/bot-response.model";
import { IChannelMeApi } from "../models/channelMeApi.model";
import { ChannelMeResponseModel } from "../models/channelMeResponse.model";
import { IHandoverProps } from "../models/handoverProps.model";

declare let chat_api: () => IChannelMeApi;

export class ChannelMe {
    chat: IChannelMeApi;
    $newMessage: (msg: BotResponseModel) => void;
    $end: () => void;
    $typing: (typing: boolean) => void;
    $propRecieved: (props: IHandoverProps) => void;
    history: IHistoryData[];

    public stop = this._stop;

    constructor(
        response: BotResponseModel,
        onNewMessage: any,
        onTyping: any,
        onPropRecieved: any,
        onEnd: any,
        history: IHistoryData[]
    ) {
        this.$newMessage = onNewMessage;
        this.$typing = onTyping;
        this.$propRecieved = onPropRecieved;
        this.$end = onEnd;
        this.history = history;

        const typing = this.$typing;
        const newMessage = this.$newMessage;
        const propRecieved = this.$propRecieved;
        const end = this.$end;
        const sendHistory = () => this._sendHistory(this.history, this.chat);

        const callback = (message: ChannelMeResponseModel) => {
            switch (message.type) {
                case "chat/initialized":
                    newMessage(
                        new BotResponseModel(
                            {
                                reply: `Je staat in de wacht voor onze livechat. Een van onze medewerkers komt zo bij je.`
                            } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    break;
                case "agent/changed":
                    newMessage(
                        new BotResponseModel(
                            { reply: `Je bent nu verbonden met ${message.agent.name}` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    break;
                case "agent/typing/started":
                    typing(true);
                    break;
                case "agent/typing/stopped":
                    typing(false);
                    break;
                case "chat/ended":
                    newMessage(
                        new BotResponseModel(
                            { reply: `Verbinding met een medewerker is verbroken` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    end();
                    break;
                case "chat/status":
                    if (message.messages.length) {
                        const mostRecentMessage = message.messages[0];
                        if (
                            mostRecentMessage.type === "message" &&
                            mostRecentMessage.text &&
                            mostRecentMessage.text.indexOf("**ChatHistory**") === -1 &&
                            mostRecentMessage.user_id
                        ) {
                            newMessage(
                                new BotResponseModel(
                                    { reply: mostRecentMessage.text } as IExpressionData,
                                    UserType.Agent,
                                    "expression"
                                )
                            );
                        }
                    }
                    break;
                case "chat/started":
                    sendHistory();
                    break;
                case "error/agents/offline":
                case "error/agents/noresponse":
                    newMessage(
                        new BotResponseModel(
                            { reply: `Verbinding met een medewerker is niet mogelijk` } as IExpressionData,
                            UserType.System,
                            "expression"
                        )
                    );
                    break;
                default:
                    // `error/*`
                    break;
            }
        };

        const payload = (response.data as IHandoverData).payload as IChannelMeHandoverData;

        if (payload.account_code) {
            const script = document.createElement("script");
            script.src = "https://channel.me/chatapi.js";
            script.onload = () => {
                this.chat = chat_api();
                this.chat = this.chat.init(payload.account_code, callback, { group: payload.group });
            };
            document.head.appendChild(script);
        }
    }

    private _stop() {
        this.chat.stop();
    }

    public sendTyping = (state: boolean) => this._sendUserTyping(state);
    private _sendUserTyping(state: boolean) {
        // To be implemented
    }

    public sendAttachement = (event: Event) => { // TODO
    };

    public sendMessage = (msg: string, name: string) => this._sendUserMessage(msg, name, this.chat);
    private _sendUserMessage(msg: string, name: string, chat: IChannelMeApi) {
        chat.send(msg);
    }

    private _sendHistory(history: IHistoryData[], chat: IChannelMeApi) {
        const chatLog = history.map((item: IHistoryData) => `${item.agent.trim()}: ${item.message.trim()}`);
        chatLog.unshift("**ChatHistory**");
        this._sendUserMessage(chatLog.join("\n"), "handover chatbot", chat);
    }
}
