import { BotResponseModel } from "../models/bot-response.model";
import { TaskModel } from "../models/task.model";

export default class TimerQueue {
    private _tasks: TaskModel[] = [];
    private _currentTimer: number;

    constructor(private onRequestPending: (pending: boolean) => void) {}

    addTask(
        sendMessage: (message: BotResponseModel) => void,
        message: BotResponseModel,
        delay: number,
        onTyping: (start: boolean) => void
    ) {
        // add task to array.
        this._tasks.push({ sendMessage, message, delay, onTyping });

        // if there is a current task running stop process here.
        if (this._currentTimer) {
            return;
        }

        // Start the new task
        this._startNewTask();
    }

    private _startNewTask() {
        // if there is a current task running stop the process
        if (this._currentTimer) {
            return;
        }

        // get the next task in the queue
        const nextTask = this._tasks.shift();
        // clear timer when there is no new task.
        if (!nextTask) {
            this.onRequestPending(false);
            return this._clearTimer();
        }

        const { message, delay, onTyping } = nextTask;

        onTyping(true);

        const delayTime = message.type === "expression" ? delay : 0;
        if (delayTime > 0) {
            this._currentTimer = window.setTimeout(() => this.runTask(nextTask), delayTime);
        } else {
            this.runTask(nextTask)
        }
    }

    private _clearTimer() {
        if (this._currentTimer) {
            clearTimeout(this._currentTimer);
        }
        this._currentTimer = null;
    }

    private runTask(task: TaskModel) {
        const { sendMessage, message, onTyping } = task;
        onTyping(false);
        sendMessage(message);
        this._currentTimer = null;
        this._startNewTask();
    }
}
