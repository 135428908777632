import { BotsSDK } from "../BotsSDK";
import { WidgetConfigModel } from "../models/widget-config.model";
import { WidgetBase } from "./base";

export class Widget extends WidgetBase {

  constructor(public sdk: BotsSDK, overrideConfig: Partial<WidgetConfigModel>) {
    super(sdk, overrideConfig);
  }

}
