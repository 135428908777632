import { FieldModel, FormModel } from "../models/form.model";
import { createElement } from "./dom-elements";

export const createForm = (config: FormModel, onSuccess: (formData: FormData) => void, onCancel: () => void): HTMLElement => {
  const formModal = createElement(document, "div", "form-modal") as HTMLElement;
  const form = createElement(document, "form") as HTMLFormElement;
  form.addEventListener("submit", (event: Event) => {
      event.preventDefault();
      onSuccess(new FormData(form))
  }, { once: true });
  formModal.append(form);

  const title = createElement(document, "h1");
  title.innerText = config.title;
  form.append(title);

  config.fields.forEach((field: FieldModel) => {
      const label = createElement(document, "label", "form-label") as HTMLLabelElement;
      label.htmlFor = field.name;
      label.innerText = field.label;
      const input =  createElement(document, "input", "form-input") as HTMLInputElement;
      input.type = field.type;
      input.name = field.name;
      input.id = field.name;
      label.append(input);
      form.append(label);
  })

  const buttonContainer = createElement(document, "div", "button-container");
  form.append(buttonContainer);

  const cancelButton = createElement(document, "button", "button") as HTMLButtonElement;
  cancelButton.type = "button";
  cancelButton.innerText = config.cancelLabel;
  cancelButton.addEventListener("click", onCancel, { once: true });
  buttonContainer.append(cancelButton);

  const sendButton = createElement(document, "button", "button") as HTMLButtonElement;
  sendButton.type = "submit";
  sendButton.innerText = config.sendLabel;
  buttonContainer.append(sendButton);

  return formModal;
}
