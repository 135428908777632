import { I18n } from "i18n-js";
import { WidgetConfigModel } from "../models/widget-config.model";
import { getSvg } from "./svg";
import UI from "./ui";

export default class AlternativeReplyBox {
    config: WidgetConfigModel;
    ui: UI;
    i18n: I18n;

    public el: HTMLElement;
    handleChoice: Function;
    showCancel: boolean;

    constructor(
        config: WidgetConfigModel,
        handleChoice: Function,
        showCancel: boolean,
        ui: UI,
        i18n: I18n
    ) {
        this.config = config;
        this.ui = ui;
        this.i18n = i18n;
        this.handleChoice = handleChoice;
        this.showCancel = showCancel;
        this.el = this.create();
    }

    public create() {
        const container = document.createElement("div");
        this.ui.addStyle(container, 'background-color', this.config.replybox_background_color);
        container.classList.add("quickReplies");
        container.classList.add("alternativeReplyOption");
        if (this.showCancel) container.appendChild(this.createCancel())
        return container;
    }

    public createCancel() {
        const el = document.createElement("button");
        el.innerHTML = getSvg("close");
        el.type = "button";
        el.setAttribute("title", this.i18n.t("cancel"));
        el.setAttribute("aria-label", this.i18n.t("cancel"));
        el.classList.add("cancel");
        this.setEvents(el);
        return el;
    }

    public remove() {
        this.el.parentNode.removeChild(this.el);
        this.el.removeAttribute("role")
        this.el.removeAttribute("aria-labelledby")
    }

    public focus() {
        if (!this.ui.isMobile()) {
            const el = this.el.querySelector(":scope > *:not([class=cancel])") as HTMLElement;
            setTimeout(() => el.focus(), 0);
        }
    }

    public labelBy(id: string) {
        if (!this.ui.isMobile()) {
            this.el.setAttribute("role", "group")
            this.el.setAttribute("aria-labelledby", id)
        }
    }
    
    public setEvents(el: HTMLElement) {
        el.addEventListener("click", _ => this.handleChoice({
            intent: "cancel",
            type: "picker_action"
        }))
    }
}