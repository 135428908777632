import axios, { AxiosResponse } from "axios";

import { UserType } from "../enums/user-type.enum";
import {
    BotResponseModel,
    IExpressionData,
    IHandoverData,
    IHistoryData,
    IPureConnectHandoverData,
    IPureConnectParam
} from "../models/bot-response.model";
import { IHandoverProps } from "../models/handoverProps.model";
import {
    IPureconnectMessageModel,
    IPureconnectResponseModel,
    IPureconnectStartChatModel
} from "../models/pureconnectResponse.model";
import { unescapeStringContent } from "../util/util";

export class PureConnect {
    public chat: any;
    public sendAttachement = this._sendAttachement;
    public sendMessage = this._sendUserMessage;
    public sendTyping = this._sendUserTyping;
    public stop = this._stop;
    $newMessage: (msg: BotResponseModel) => void;
    $typing: (typing: boolean) => void;
    $propRecieved: (props: IHandoverProps) => void;
    $end: () => void;

    history: IHistoryData[];
    payload: IPureConnectHandoverData;
    userId: string;
    interval: number;
    pollWaitSuggestion: number;

    constructor(
        response: BotResponseModel,
        onNewMessage: any,
        onTyping: any,
        onPropRecieved: any,
        onEnd: any,
        history: IHistoryData[]
    ) {
        this.payload = (response.data as IHandoverData).payload as IPureConnectHandoverData;
        this._createConversation();
        this.$newMessage = onNewMessage;
        this.$typing = onTyping;
        this.$propRecieved = onPropRecieved;
        this.$end = onEnd;
        this.history = history;
    }

    private _stop() {
        this.endConnectionPureConnect();
        clearInterval(this.interval);
    }

    private _sendAttachement(event: Event) {
        // TODO
    }

    private _sendUserMessage(msg: string) {
        this._sendToPureConnect(msg, (responses: IPureconnectResponseModel) =>
            this._handleIncommingMessages(responses)
        );
    }

    private async _createConversation() {
        const url = `${this.payload.handover_base_url}websvcs/chat/start`;
        const data = {
            attributes: this.formatParams(this.payload.params),
            clientToken: "deprecated",
            emailAddress: null as any,
            language: "en-us",
            participant: { name: this.payload.name || "Gebruiker", credentials: "" },
            supportedContentTypes: "text/plain",
            target: this.payload.chatbox,
            targetType: "Workgroup",
            transcriptRequired: false
        };
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            const response = await axios.post(url, data, config);
            const result: IPureconnectResponseModel = response.data;
            this.userId = (result.chat as IPureconnectStartChatModel).participantID;
            this.pollWaitSuggestion = result.chat.pollWaitSuggestion;
            this._sendHistory();
            this._startPollingForMessages();
        } catch (e) {
            return console.log("Failed to createConversation");
        }
    }

    private formatParams(params: IPureConnectParam[]) {
        const intitalValue: { [key: string]: string } = {};
        return params.reduce((o, val) => {
            o[val.key] = val.value;
            return o;
        }, intitalValue);
    }

    private _sendHistory() {
        const chatLog = this.history.map((item: IHistoryData) => `${item.agent.trim()}: ${item.message.trim()}`);
        chatLog.unshift("History");
        this._sendUserMessage(chatLog.join("\n"));
    }

    private _startPollingForMessages() {
        const url = `${this.payload.handover_base_url}websvcs/chat/poll/${this.userId}`;
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        this.interval = window.setInterval(() => {
            try {
                axios.get(url, config).then((response: AxiosResponse) => {
                    this._handleIncommingMessages(response.data);
                });
            } catch (e) {
                clearInterval(this.interval);
                this.$end();
            }
        }, this.pollWaitSuggestion);
    }

    private _handleIncommingMessages(response: IPureconnectResponseModel) {
        response.chat.events.forEach((event: IPureconnectMessageModel) => {
            if (event.participantType === "Agent") {
                if (event.type === "text" || event.type === "url") {
                    this._onNewMessage(event, UserType.Agent);
                }
                if (event.state === "disconnected") {
                    this._onTyping(false);
                    event.value = "Je bent niet meer verbonden met een medewerker.";
                    this._onNewMessage(event, UserType.System);
                }
            }

            if (event.type === "typingIndicator") {
                this._onTyping(event.value as boolean);
            }

            if (event.participantType === "System" && event.type === "text") {
                event.value = (event.value as string).trim();
                if (event.value) this._onNewMessage(event, UserType.System);
            }
        });
    }

    private _onTyping(isTyping: boolean) {
        this.$typing(isTyping);
    }

    private _onNewMessage(message: IPureconnectMessageModel, type: UserType) {
        this.$newMessage(
            new BotResponseModel({ reply: message.value } as IExpressionData, type, "expression")
        );
    }

    private async _sendToPureConnect(message: string, callback: (response: IPureconnectResponseModel) => void) {
        const url = `${this.payload.handover_base_url}websvcs/chat/sendMessage/${this.userId}`;
        const data = {
            contentType: "text/plain",
            message: unescapeStringContent(message)
        };
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            const response = await axios.post(url, data, config);
            const result: IPureconnectResponseModel = response.data;
            callback(result);
        } catch (e) {
            return console.log("Failed to send message");
        }
    }

    private async _sendUserTyping(typing: boolean) {
        const url = `${this.payload.handover_base_url}websvcs/chat/setTypingState/${this.userId}`;
        const data = {
            contentType: "application/json",
            typingIndicator: typing
        };
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            await axios.post(url, data, config);
        } catch (e) {
            return console.log("Failed to send typing");
        }
    }

    private async endConnectionPureConnect() {
        const url = `${this.payload.handover_base_url}websvcs/chat/exit/${this.userId}`;
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            await axios.post(url, null, config);
        } catch (e) {
            return console.log("Failed to end connection");
        }
    }
}
