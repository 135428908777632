export const createElement = (
    domRef: Document,
    el: string,
    className?: string | string[],
    id?: string
): HTMLElement => {
    const element = domRef.createElement(el);
    if (!!className) {
        if (typeof className === "string") {
            element.classList.add(className as string);
        } else if (Array.isArray(className)) {
            element.classList.add(...(className as string[]));
        }
    }

    if (!!id) {
        element.id = id;
    }

    return element;
};
