import { BotsSDK } from "../BotsSDK";
import { WidgetConfigModel } from "../models/widget-config.model";
import { Widget } from "../widgets/default";

class OBIbots {
    public init = this.onInit;
    private sdkInstance: BotsSDK;
    private widget: Widget;

    constructor(private guid: string, config: Partial<WidgetConfigModel>, private knowledge: {}) {
        this.onInit(config);
    }

    public toggle() {
        if (!this.widget.isInitialized) {
            this.widget.initWidget()
        }

        this.widget.toggleWidgetFrame()
    }

    private onInit(config: Partial<WidgetConfigModel>) {
        this.sdkInstance = new BotsSDK(this.guid, this.knowledge)
        this.widget = new Widget(this.sdkInstance, config);
    }
}

export function bots(guid: string = "308e4bd5-d28e-4ab6-acdb-a4ecad1a05b6", config: Partial<WidgetConfigModel> = {}, knowledge: {} = {}) {
    return new OBIbots(guid, config, knowledge);
}
