import axios, { AxiosResponse } from 'axios';

import { QUANDAGO_CONV_KEY, QUANDAGO_USER_INFO } from '../constants';
import { UserType } from '../enums/user-type.enum';
import {
    BotResponseModel,
    IExpressionData,
    IHandoverData,
    IHistoryData,
    IQuandagoHandoverData,
} from '../models/bot-response.model';
import { IHandoverProps } from '../models/handoverProps.model';
import { QuandagoResponseModel } from '../models/quandagoResponse.model';
import { SessionInfoModel } from '../models/session-info.model';
import SessionStorage from '../util/sessionStorage';
import { unescapeStringContent } from '../util/util';

export class QuandaGo {
    public chat: any;
    public sendAttachement = this._sendAttachement;
    public sendMessage = this._sendUserMessage;
    public sendTyping = this._sendUserTyping;
    public stop = this._stop;
    $newMessage: (msg: BotResponseModel) => void;
    $typing: (typing: boolean) => void;
    $propRecieved: (props: IHandoverProps) => void;
    $end: () => void;

    history: IHistoryData[];
    sessionInfo: SessionInfoModel;
    payload: IQuandagoHandoverData;
    userId: string;
    convKey: string;
    interval: number;
    baseUrl: string;

    constructor(
        response: BotResponseModel,
        onNewMessage: any,
        onTyping: any,
        onPropRecieved: any,
        onEnd: any,
        history: IHistoryData[],
        sessionInfo: SessionInfoModel
    ) {
        this.payload = (response.data as IHandoverData).payload as IQuandagoHandoverData;
        this._createUser();
        this.$newMessage = onNewMessage;
        this.$typing = onTyping;
        this.$propRecieved = onPropRecieved;
        this.$end = onEnd;
        this.history = history;
        this.sessionInfo = sessionInfo;
        this.baseUrl = process.env.API_BASE_URL;
    }

    private _stop() {
        clearInterval(this.interval);
    }

    private _sendUserTyping(_state: boolean) {
        // To be implemented
    }

    private _sendAttachement(_event: Event) {
        // TODO
    }

    private _onPropRecieved(props: IHandoverProps) {
        this.$propRecieved(props)
    }

    private _sendUserMessage(msg: string) {
        this._sendToQuandago(msg, (responses: QuandagoResponseModel[]) => this._handleIncommingMessages(responses));
    }

    private async _createUser() {
        const isHandoverState = !!SessionStorage.get(QUANDAGO_USER_INFO);
        if (!isHandoverState) {
            const url = `${this.payload.handover_base_url}/createuser.json`;
            const data = encodeURI(`alias=${this.payload.name}&email=${this.payload.email}`);
            const config = {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                withCredentials: true
            };
            try {
                const response = await axios.post(url, data, config);
                SessionStorage.add(QUANDAGO_USER_INFO, JSON.stringify(response.data));
                if (response.data.hasOwnProperty("user_id")) {
                    this.userId = response.data.user_id;
                    this._createConversation();
                }
            } catch (e) {
                return console.log("Failed to createUser");
            }
        } else {
            const convKey = SessionStorage.get(QUANDAGO_CONV_KEY);
            const userInfo = SessionStorage.get(QUANDAGO_USER_INFO);
            this.convKey = convKey;
            this.userId = JSON.parse(userInfo).user_id;
            this._startPollingForMessages();
        }
    }

    private async _storeConversationInfo(convKey: string) {
        const url = `${this.baseUrl}/chat/${this.sessionInfo.chatbot}/${this.sessionInfo.session}/`;

        const userInfo = SessionStorage.get(QUANDAGO_USER_INFO);
        const payload = {
            payload: {
                type: "metadata",
                data: {
                    user: JSON.parse(userInfo),
                    conversation_id: convKey,
                    provider: "quandago"
                }
            }
        };

        try {
            await axios.post(url, payload);
        } catch (e) {
            console.log(e.message);
        }
    }

    private async _createConversation() {
        const url = `${this.payload.handover_base_url}/createconversation/${this.payload.chatbox}.json`;
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            const response = await axios.post(url, null, config);
            if (response.data.hasOwnProperty("conv_key")) {
                this.convKey = response.data.conv_key;
                this._sendHistory();
                this._setTags();
                this._startPollingForMessages();
                SessionStorage.add(QUANDAGO_CONV_KEY, this.convKey);

                this._storeConversationInfo(this.convKey);
            }
        } catch (e) {
            return console.log("Failed to createConversation");
        }
    }

    private async _setTags() {
        const url = `${this.payload.handover_base_url}/tags/${this.convKey}.json`;
        const data = encodeURI(`tags=${this.sessionInfo.session}`);
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            await axios.post(url, data, config);
        } catch (e) {
            return console.log("Failed to set Tags");
        }
    }

    private _sendHistory() {
        const chatLog = this.history.map((item: IHistoryData) => `${item.agent.trim()}: ${item.message.trim()}`);
        chatLog.unshift("History");
        this._sendUserMessage(chatLog.join("\n").toString().replace(/</g, "&lt;"));
    }

    private _startPollingForMessages() {
        const url = `${this.payload.handover_base_url}/poll/0.json`;
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        this.interval = window.setInterval(async () => {
            try {
                await axios.get(url, config).then((response: AxiosResponse) => {
                    if (response.data) {
                        this._handleIncommingMessages(response.data);
                    }
                    this._onPropRecieved({connected: true});
                }, _ => {
                    this._onPropRecieved({connected: false});
                });
            } catch (e) {
                clearInterval(this.interval);
            }
        }, 2000);
    }

    private _handleIncommingMessages(messages: QuandagoResponseModel[]) {
        messages.forEach((message: QuandagoResponseModel) => {
            switch (message.t) {
                case "msg":
                    this._onNewMessage(message, UserType.Agent);
                    break;
                case "prop":
                    this._handleProps(message);
                    break;
                case "queued":
                    message.message = `Je staat nu in de wachtrij`;
                    this._onNewMessage(message, UserType.System);
                    break;
                case "join":
                    message.message = `Een medewerker neemt nu deel aan de chat`;
                    this._onNewMessage(message, UserType.System);
                    break;
                case "leave":
                    message.message = `De medewerker heeft de chat verlaten`;
                    this._storeConversationInfo(null);
                    this._onNewMessage(message, UserType.System);
                    this.$end();
                    clearInterval(this.interval);
                    break;
            }
        });
    }

    private _handleProps(prop: QuandagoResponseModel) {
        if (prop.prop_name == "typing") {
            this.$typing(!!parseInt(prop.prop_value, 10));
        }
    }

    private _onNewMessage(message: QuandagoResponseModel, userType: UserType) {
        if (this.userId !== message.user_id) {
            this.$newMessage(
                new BotResponseModel({ reply: unescapeStringContent(message.message) } as IExpressionData, userType, "expression")
            );
        }
    }

    private async _sendToQuandago(message: string, callback: (response: QuandagoResponseModel[]) => void) {
        const url = `${this.payload.handover_base_url}/message/${this.convKey}.json`;
        const data = "message=" + encodeURIComponent(unescapeStringContent(message));
        const config = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            withCredentials: true
        };
        try {
            const response = await axios.post(url, data, config);
            callback(response.data);
        } catch (e) {
            return console.log("Failed to send message");
        }
    }
}
