export enum AvayaResponseType {
    Notification = "notification"
}

export enum AvayaResponseBodyMethod {
    RequestChat = "requestChat",
    Pong = "pong",
    IsTyping = "isTyping",
    NewParticipant = "newParticipant",
    ParticipantLeave = "participantLeave",
    NewMessage = "newMessage",
    NewAgentFileTransfer = "newAgentFileTransfer"
}

export enum AvayaResponseRole {
    ActiveParticipant = "active_participant"
}

export enum AvayaResponseSenderType {
    LiveAgent = "live_agent"
}

export enum AvayaResponseMessageType {
    Message = "message"
}

export enum AvayaResponseLeaveReason {
    Default = "default"
}
