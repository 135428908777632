export enum Features {
  Dashboard = 1,
  Build = 2,
  Deploy = 4,
  Integrate = 8,
  Messages = 16,
  Settings = 32,
  History = 64,
  ChatbotStatus = 128,
  RequestPending = 256,
  SlackIntegration = 512,
  GlobalRegEx = 1024,
  AttachmentTurnBeta = 2048,
  NewExtractionRulesConversion = 4096,
  UserRoles = 8192,
  JccIntegration = 16384,
  ConnectionPending = 32768
}
