import { Features } from "../enums/features.enum";

export default class FeatureToggle {

  constructor(private tf: number) {
  }

  public check(feature: Features) {
    if (this.tf & feature) {
        return true;
    } else {
        return false;
    }
  }
}
