export class FormModel {
    constructor(
        public cancelLabel: string,
        public fields: FieldModel[],
        public sendLabel: string,
        public title: string,
    ) {}
}

export class FieldModel {
  constructor(
      public name: string,
      public type: string,
      public label: string,
  ) {}
}
