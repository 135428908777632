export default class LocalStorage {

    static prefix = "obi-";

    static get(key: string) {
        const item = window.localStorage.getItem(this.prefix + key);
        if (item) {
            return item;
        } else {
            return null;
        }
    }

    static add(key: string, value: string) {
        window.localStorage.setItem(this.prefix + key, value);
    }

    static remove(key: string) {
        window.localStorage.removeItem(this.prefix + key);
    }

    static clear() {
        for (const key in window.localStorage) {
            if (localStorage.hasOwnProperty(key) && key.indexOf(this.prefix) > -1) window.localStorage.removeItem(key);
        }
    }
}
