import { BotResponseModel } from "./bot-response.model";

export class SessionInfoModel {
  constructor(
    public chatbot: string = "",
    public session: string = "",
    public user: string = "",
    public messages: BotResponseModel[] = [],
  ) {}
}
